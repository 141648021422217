const codigosPostales = [
  { cp: 'AD', provincia: 'Andorra' },
  { cp: '01', provincia: 'Álava' },
  { cp: '02', provincia: 'Albacete' },
  { cp: '03', provincia: 'Alicante' },
  { cp: '04', provincia: 'Almería' },
  { cp: '05', provincia: 'Ávila' },
  { cp: '06', provincia: 'Badajoz' },
  { cp: '07', provincia: 'Illes Balears' },
  { cp: '08', provincia: 'Barcelona' },
  { cp: '09', provincia: 'Burgos' },
  { cp: '10', provincia: 'Cáceres' },
  { cp: '11', provincia: 'Cádiz' },
  { cp: '12', provincia: 'Castellón' },
  { cp: '13', provincia: 'Ciudad Real' },
  { cp: '14', provincia: 'Córdoba' },
  { cp: '15', provincia: 'A Coruña' },
  { cp: '16', provincia: 'Cuenca' },
  { cp: '17', provincia: 'Girona' },
  { cp: '18', provincia: 'Granada' },
  { cp: '19', provincia: 'Guadalajara' },
  { cp: '20', provincia: 'Gipuzkoa' },
  { cp: '21', provincia: 'Huelva' },
  { cp: '22', provincia: 'Huesca' },
  { cp: '23', provincia: 'Jaén' },
  { cp: '24', provincia: 'León' },
  { cp: '25', provincia: 'Lleida' },
  { cp: '26', provincia: 'La Rioja' },
  { cp: '27', provincia: 'Lugo' },
  { cp: '28', provincia: 'Madrid' },
  { cp: '29', provincia: 'Málaga' },
  { cp: '30', provincia: 'Murcia' },
  { cp: '31', provincia: 'Navarra' },
  { cp: '32', provincia: 'Ourense' },
  { cp: '33', provincia: 'Asturias' },
  { cp: '34', provincia: 'Palencia' },
  { cp: '35', provincia: 'Las Palmas' },
  { cp: '36', provincia: 'Pontevedra' },
  { cp: '37', provincia: 'Salamanca' },
  { cp: '38', provincia: 'Santa Cruz de Tenerife' },
  { cp: '39', provincia: 'Cantabria' },
  { cp: '40', provincia: 'Segovia' },
  { cp: '41', provincia: 'Sevilla' },
  { cp: '42', provincia: 'Soria' },
  { cp: '43', provincia: 'Tarragona' },
  { cp: '44', provincia: 'Teruel' },
  { cp: '45', provincia: 'Toledo' },
  { cp: '46', provincia: 'Valencia' },
  { cp: '47', provincia: 'Valladolid' },
  { cp: '48', provincia: 'Bizkaia' },
  { cp: '49', provincia: 'Zamora' },
  { cp: '50', provincia: 'Zaragoza' },
  { cp: '51', provincia: 'Ceuta' },
  { cp: '52', provincia: 'Melilla' },
]

export default codigosPostales
