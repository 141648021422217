import { NavisionState } from '../../../src/common/components/FunnelSelector/Common/SearchResults'
import { SearchState } from '../../../src/common/context/reducers/searchReducer'
import {
  SelectorState,
  Temporada,
} from '../../../src/common/context/reducers/selectorReducer'
import dataLayer from '../../../src/common/helpers/functions/dataLayer'
import route from '../../../src/common/utils/route'

export type GmtTemporada = 'invierno' | 'verano' | 'cuatro_estaciones'

export const temporadaMapping: Record<Temporada, GmtTemporada> = {
  invierno: 'invierno',
  verano: 'verano',
  allseason: 'cuatro_estaciones',
}

export const reportSearch = (selectorState: SelectorState) => {
  switch (selectorState.origen) {
    case route('landing-invierno'):
      dataLayer.push({
        event: 'buscar_neumaticos',
        action: 'invierno',
        label: 'desktop',
        tipo_neumaticos: 'invierno',
        runflat: !!selectorState.runflat.value,
        marca: 'all',
      })
      break
    case route('landing-allseason'):
      dataLayer.push({
        event: 'buscar_neumaticos',
        action: 'cuatro_estaciones',
        label: 'desktop',
        tipo_neumaticos: 'cuatro_estaciones',
        runflat: !!selectorState.runflat.value,
        marca: 'all',
      })
      break
    case route('landing-furgoneta'):
      dataLayer.push({
        event: 'buscar_neumaticos',
        action: 'furgoneta',
        label: 'desktop',
        tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        runflat: !!selectorState.runflat.value,
        marca: 'all',
      })
      break
    case route('landing-4x4'):
      dataLayer.push({
        event: 'buscar_neumaticos',
        action: '4x4',
        label: 'desktop',
        tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        runflat: !!selectorState.runflat.value,
        marca: 'all',
      })
      break
    default:
      dataLayer.push({
        event: 'buscar_neumaticos',
        action: 'general',
        label: 'desktop',
        tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        runflat: !!selectorState.runflat.value,
        marca: 'all',
      })
  }
}

export const reportResults = (
  props:
    | { page: 'resultados'; state: NavisionState }
    | { page: 'todos'; state: SearchState }
) => {
  switch (props.page) {
    case 'resultados': {
      // need to create array with the order that is displayed to know the position of each tyre
      const premium = props.state.results.neumaticos.filter(
        item => item.categoria.toUpperCase() === 'PREMIUM'
      )
      const budget = props.state.results.neumaticos.filter(
        item => item.categoria.toUpperCase() === 'BUDGET'
      )
      const quality = props.state.results.neumaticos.filter(
        item => item.categoria.toUpperCase() === 'QUALITY'
      )
      const newResults = [...premium, ...quality, ...budget]
      dataLayer.push({
        event: 'neumatico_solicitud_impresion',
        ecommerce: {
          currencyCode: 'EUR',
          impressions: newResults.map((item, index) => ({
            name: `${item.marca} ${item.modelo}`,
            id: item.id,
            price: item.precio,
            brand: item.marca,
            category: 'Neumáticos',
            list: 'recomendados',
            position: index + 1,
          })),
        },
      })
      break
    }
    case 'todos':
      dataLayer.push({
        event: 'neumatico_solicitud_impresion',
        ecommerce: {
          currencyCode: 'EUR',
          impressions: props.state.results.neumaticos.map((item, index) => ({
            name: `${item.marca} ${item.modelo}`,
            id: item.id,
            price: item.precio,
            brand: item.marca,
            category: 'Neumáticos',
            list: 'all',
            position:
              props.state.query.pagination.page *
                props.state.query.pagination.pageSize +
              (index + 1),
          })),
        },
      })
  }
}
